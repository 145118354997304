<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :propsParam="propsParam" :filters="filters">
      <div slot="content-buttons-table-header"></div>
      <div slot="content-filter-horizontal">
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";

import { mapState } from "vuex";

export default {
  name: "InvoiceToProfitListView",
  components: {
    ViewTemplateWithTable, RadioGroup
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Faturamento",
        title: "A Faturar",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/billing/invoice/get-all-to-profit",
        showChecks: false,
        headerTable: [
          {
            title: "Número/Efetivação",
            field: "numberAndDate",
            type: "text",
            iconSearch: true,
          },
          {
            field: "fullName",
            fieldSecond: "identification",
            title: "Nome",
            type: "text",
            styleBody: "max-width: 200px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
            iconSearch: true,
          },
          {
            field: "consultant",
            title: "Consultor",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleBody: "max-width: 80px;white-space: nowrap; overflow: hidden;text-overflow: ellipsis;",
          },
          {
            field: "period",
            title: "Período(Início/Fim)",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            styleBody: "max-width: 300px;",
          },
          {
            field: "billingDateStr",
            title: "Dt Fatura...",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Status",
            type: "html",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "dayAfter",
            title: "Pen..Dia(s)",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            typeButton: "default",
            classCssBody: "text-center",
            styleBody: "width: 80px;",
            button: {
              classIcon: "fa-solid fa-file-invoice-dollar",
              title: "Gerar",
              type: "primary",
              size: "small",
              eventName: "generateBilling",
            },
          },
        ],
      },
      filters: [
        {
          type: "radio",
          title: "Antes ou Depois",
          field: "BeforeAfter",
          options: [
            { text: "Antes", value: "Antes" },
            { text: "Depois", value: "Depois" },
          ],
          value: [],
        },
        {
          type: "multiOptions",
          title: "Consultor",
          field: "Consultant",
          url: "/api/v1/human-resources/collaborator/select-all",
          propsParams: { isConsultant: true },
          value: "",
        },
        {
          type: "number",
          title: "Dia(s) de Faturamento",
          field: "Day",
          value: "",
        },
        {
          type: "radio",
          title: "Período",
          field: "Period",
          options: [
            { text: "Efetivação", value: "Efetivação" },
            { text: "Inicio da Locação", value: "Inicio da Locação" },
            { text: "Fim da Locação", value: "Fim da Locação" },
          ],
          value: [],
        },
        {
          type: "options",
          title: "Status",
          field: "Status",
          options: [
            { text: "Pendente", value: "Pendente" },
            { text: "Hoje", value: "Hoje" },
            { text: "Agendada", value: "Agendada" },
          ],
          value: [],
        },
        {
          type: "decimal",
          title: "Valor",
          field: "Value",
          valueStart: 0,
          valueEnd: 0,
        },
      ],
      status: this.$route.params.status,
      propsParam: {
        any: "Data de Faturamento",
        status: ""
      },
    };
  },
  created() {
    if (this.status) {
      if (this.status == "delayed") this.propsParam.status = "Pendente";
      if (this.status == "today") this.propsParam.status = "Hoje";
      if (this.status == "week") this.propsParam.status = "Semana Atual";
      if (this.status == "month") this.propsParam.status = "Mês Atual";
      if (this.status == "year") this.propsParam.status = "Ano Atual";
    }
  },
  computed: {
    ...mapState("generic", ["selected", "event"]),
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "generateBilling") {
          this.$router.push({
            name: "generateBilling",
            params: { rentId: this.event.data.rentId, customerId: this.event.data.customerId },
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.badge-default {
  background-color: #eeeeee;
  font-size: 13px !important;
  border-radius: 50px !important;
}

.invoice-box {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin-right: 8px;
}

.invoice-delay {
  background-color: red;
}

.invoice-today {
  background-color: #ff8a1b;
}

.invoice-scheduled {
  background-color: #3d4eae;
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}

.invoice-receive {
  color: #0008AA;
}
</style>